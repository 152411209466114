import React from "react";
import { graphql, Link } from "gatsby";
import Seo from "../components/seo";
import get from "lodash/get";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import Layout from "../components/layout";
import FileSmall from "../components/file-small";
import SidebarContactForm from "../components/sidebar-contact-form";

class CaseResultTemplate extends React.Component {
  render() {
    const caseResult = get(this.props, "data.contentfulCaseResult");

    return (
      <Layout location={this.props.location}>
        <Seo
          title={`${caseResult.title}`}
          description={`${caseResult.title}`}
          keywords={caseResult.tags.join()}
        />

        <section className="m-3">
          <nav class="breadcrumb" aria-label="breadcrumbs">
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/our-victories/">Our Victories</Link>
              </li>
              <li class="is-active">
                <Link to="#" aria-current="page">
                  {caseResult.title}
                </Link>
              </li>
            </ul>
          </nav>
          <div className="columns">
            <div className="column">
              {caseResult.image !== null && (
                <figure className="image page-image is-pulled-right mb-3 mx-4">
                  <GatsbyImage
                    image={getImage(caseResult.image)}
                    alt={caseResult.image.title}
                    width={caseResult.image.gatsbyImageData.width}
                    height={caseResult.image.gatsbyImageData.height}
                  />
                </figure>
              )}
              <div className="content">
                {caseResult.tags !== null &&
                  caseResult.tags.map((obj) => (
                    <span className="tag is-primary is-capitalized mr-1 has-text-weight-semibold">
                      {obj}
                    </span>
                  ))}
                <h1 className="mt-1">{caseResult.title}</h1>

                <div className="content mt-5">
                  {renderRichText(caseResult.body)}
                </div>
                <div className="content my-2">
                  {caseResult.files !== null &&
                    caseResult.files.map((file) => <FileSmall file={file} />)}
                </div>
              </div>
              <Link className="button is-primary" to={`/our-victories/`}>
                More Case Results...
              </Link>
            </div>
            <div className="column is-one-third">
              <SidebarContactForm />
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

export default CaseResultTemplate;

export const pageQuery = graphql`
  query CaseResultBySlug($slug: String!) {
    contentfulCaseResult(slug: { eq: $slug }) {
      title
      tags
      slug
      body {
        raw
      }
      files {
        description
        title
        file {
          url
        }
      }
      image {
        title
        gatsbyImageData(
          layout: FULL_WIDTH
          resizingBehavior: SCALE
          placeholder: TRACED_SVG
        )
      }
    }
  }
`;
