import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";

const FileSmall = ({ file }) => (
  <article className="media">
    <figure className="media-left">
      <p className="image is-32x32">
        <FontAwesomeIcon size="3x" icon={faFilePdf} />
      </p>
    </figure>
    <div className="media-content">
      <div className="content">
        <p>
          <strong>
            <a
              href={file.file.url}
              target="blank"
              className="has-text-primary"
              download
            >
              {file.title}
            </a>
          </strong>
          <br />
          {file.description}
        </p>
      </div>
    </div>
  </article>
);

export default FileSmall;
